import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import axios from "axios";
import {toast} from "react-toastify";
import NotificationToast from "../Helpers/NotificationToast";
import CustomButton from "../Helpers/CustomButton";
import {openModal} from "../../store/modal/modalReducer";
import {faBed} from "@fortawesome/pro-regular-svg-icons";
import {useHistory, Link, useParams} from "react-router-dom";
import {getBooking} from "../../store/booking/bookingActions";
import ModalATLASReport from "../Modals/ModalATLASReport";

export default function QueuePlate({current_staff, current_booking, ...props}) {
	const dispatch = useDispatch();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const {type} = useParams();
	const [queueData, setQueueData] = useState([]);
	var url_string = window.location.href;
	var url = new URL(url_string);
	var tag_id = url.searchParams.get("tag");

	useEffect(() => {
		const fetchBedQueueInfo = async () => {
			try {
				// Make the API request
				const response = await axios.post(`${process.env.REACT_APP_API_URL}bed-queue`, {
					booking_id: current_booking?.id,
				});
				// Set the fetched data to the state
				setQueueData(response.data);
				setLoading(false);
			} catch (err) {
				setError("Failed to fetch bed queue data");
				setLoading(false);
			}
		};

		fetchBedQueueInfo();
	}, [current_booking]);

	// Display a loading state while fetching
	if (loading) return <div>Loading...</div>;

	// Display an error message if the API call fails
	if (error) return <div>{error}</div>;

	function autoAllocateBed() {
		setLoading(true);

		axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}auto_allocate_bed`,
			data: {
				booking_id: current_booking.id,
			},
		})
			.then((response) => {
				dispatch(getBooking(current_booking.id));
			})
			.catch((response) => {
				console.log(response);
				// toast(<NotificationToast title={"failed"} message={response.response.data} list={true} type="error" />);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	async function delinkBed() {
		setLoading(true);
		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}delink_user_bed`,
			data: {
				booking_id: current_booking.id,
				user: current_staff,
				move_location: 0,
			},
		})
			.then((response) => {
				dispatch(getBooking(current_booking.id));
			})
			.catch((error) => {
				alert("properties failed");
			})
			.finally(() => {
				setLoading(false);
			});
	}

	async function delinkUnit() {
		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}delink_user_unit`,
			data: {
				booking_id: current_booking.id,
				user: current_staff,
				move_location: 0,
			},
		})
			.then((response) => {
				dispatch(getBooking(current_booking.id));
			})
			.catch((error) => {
				alert("properties failed");
			})
			.finally(() => {
				setLoading(false);
			});
	}

	return (
		<div>
			{current_booking?.bed_id === 0 && current_booking?.unit_type?.id > 0 &&
				<CustomButton text="Auto Allocate Bed" styling="w-full mt-2" icon={faBed}
							  onClick={() => autoAllocateBed()}/>}

			<div className="text-center mt-4 mb-4">
				<p>{current_booking?.property?.name}</p>
				<p>
					{/* {current_booking?.unit?.name} {current_booking?.bed?.name} */}
					{current_booking?.unit?.name} {current_booking?.waiting_bed_id > 0 ? current_booking?.waiting_bed?.name : current_booking?.bed?.name}{" "}
					{current_booking?.waiting_bed_id > 0 ? " or similar. (on waitlist)" : ""}
				</p>
				<p>
					{current_booking?.unit_type?.name}
					{current_booking?.rates?.[current_booking.rates.length - 1]?.signed_lease_at && !current_booking?.bed?.name && " Queue"}
				</p>

				<div className="border-t-2 mt-4 pt-4">
					<div className="flex justify-between items-center">
						<div className="font-bold">BED QUEUE</div>
						<CustomButton
							text="ATLAS"
							onClick={() =>
								dispatch(
									openModal({
										modal_type: "ModalATLASReport",
										modal_props: {
											booking_id: current_booking?.id,
										},
									})
								)
							}
						/>
					</div>

					<table className="min-w-full divide-y divide-gray-300">
						<thead>
						<tr>
							<th scope="col"
								className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
								{current_booking?.property?.name}
							</th>
							<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
								Unallocated
							</th>
							<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
								Blue
							</th>
							<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
								Purple
							</th>
						</tr>
						</thead>
						<tbody className="divide-y divide-gray-200">
						{queueData?.map((queue, index) => (
							<tr key={index}>
								<td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-left">{queue.unit_type}</td>
								<td className="px-3 py-4 text-sm text-gray-500">{queue.unallocated}</td>
								<td className="px-3 py-4 text-sm text-gray-500">{queue.blue}</td>
								<td className="px-3 py-4 text-sm text-gray-500">{queue.purple}</td>
							</tr>
						))}
						</tbody>
					</table>
				</div>
			</div>

			{(current_staff.isSuper == 1 || current_staff.email == "rowan@qholdings.co.za") && (current_booking.waiting_bed_id > 0 || current_booking.bed_id > 0) && !current_staff.building_manager ? (
				<CustomButton text="De-link Bed" styling="w-full mt-2" icon={faBed} onClick={() => delinkBed()}/>
			) : (
				""
			)}

			{(current_booking.waiting_unit_id > 0 || current_booking.unit_id > 0) && !current_staff.building_manager ? (
				<CustomButton text="De-link Unit" styling="w-full mt-2" icon={faBed} onClick={() => delinkUnit()}/>
			) : (
				""
			)}

			{current_booking.student_type && current_booking?.user?.profile?.institution_study && !current_staff.building_manager ? (
				<CustomButton
					text="Manual Bed Assignment"
					styling="w-full mt-2"
					icon={faBed}
					onClick={() =>
						dispatch(
							openModal({
								modal_type: "ModalBedAssignment",
								modal_props: {
									current_booking,
									type: type,
									tag_id: tag_id,
								},
							})
						)
					}
				/>
			) : (
				""
			)}
		</div>
	);
}
